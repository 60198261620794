import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowSize } from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
  player: {
    maxWidth: '1920px',
    width: '100%'
  }
}));

export default function S8Video() {
  const classes = useStyles();
  const [width, height] = useWindowSize();

  React.useEffect(() => {

  }, [width, height])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Materiały video</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row">
        <Grid container item spacing={1} direction="column" xs={12} lg={6}>
        <Grid item>
            <Typography variant='subtitle1'>Nowości w SIVACON S8 R13 i Simaris Configuration 5.0</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 47 min</Typography>
            <video className={classes.player} controls>
              <source src="https://pricelist.nazwa.pl/PAKO/cdn/Szkolenie_z_nowosci_w_S8_R13_i_SC5.0.mp4" type="video/mp4" />
              Przeglądarka nie wspiera HTML5 video.
          </video>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>Konfigurowanie 3WA w Simaris</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 13 min</Typography>
            <video className={classes.player} controls>
              <source src="https://pricelist.nazwa.pl/PAKO/pliki/Konfigurowanie_3WA_w_Simaris.mp4" type="video/mp4" />
              Przeglądarka nie wspiera HTML5 video.
          </video>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>Szkolenie Simaris SC 4.0 i nowe wyłączniki 3WA</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 142 min</Typography>
            <video className={classes.player} controls>
              <source src="https://pricelist.nazwa.pl/PAKO/pliki/Szkolenie-Nowy_Simaris_SC_4.0_i_nowe_wylaczniki_3WA-20210319_093316-Nagrywanie_spotkania.mp4" type="video/mp4" />
              Przeglądarka nie wspiera HTML5 video.
          </video>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}