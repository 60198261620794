export const SET_DIALOG_OPEN = 'SET_DIALOG_OPEN';
export const SET_DIALOG_USERNAME = 'SET_DIALOG_USERNAME';
export const SET_DIALOG_PASSWORD = 'SET_DIALOG_PASSWORD';
export const SET_DIALOG_USERNAME_ERROR = 'SET_DIALOG_USERNAME_ERROR';
export const SET_DIALOG_PASSWORD_ERROR = 'SET_DIALOG_PASSWORD_ERROR';

export const setDialogOpen = (open, caller) => ({ type: SET_DIALOG_OPEN, open: open, caller: caller })
export const setDialogUsername = (username) => ({ type: SET_DIALOG_USERNAME, username })
export const setDialogPassword = (password) => ({ type: SET_DIALOG_PASSWORD, password })
export const setDialogUsernameError = (error) => ({type: SET_DIALOG_USERNAME_ERROR, error })
export const setDialogPasswordError = (error) => ({type: SET_DIALOG_PASSWORD_ERROR, error })