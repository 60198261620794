import { SET_USER_DATA } from '../actions/setUserData';

const initialState = {
  data: {}
}

export const partnerPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        data: action.data,
      }
    default:
      return state
  }
}