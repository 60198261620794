import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
}));

export default function SoftwarePage() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Oprogramowanie do konfiguracji</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>Bloki dynamiczne CAD dla SIVACON S4</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" href="https://pricelist.nazwa.pl/PAKO/cdn/Sivacon_S4.dwt">
            Pobierz
          </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
        <Typography variant='subtitle1'>Schemat do ćwiczeń</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="secondary" href="https://pricelist.nazwa.pl/PAKO/cdn/schemat_do_cwiczen.pdf">
         Pobierz schemat
         </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
        <Typography variant='subtitle1'>Bloki dynamiczne CAD</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="secondary" href="https://new.siemens.com/pl/pl/produkty/energetyka/niskie-napiecie/projektowanie-instalacji-elektrycznych/bloki-dynamiczne-cad.html">
         Przejdź do strony
         </Button>
        </Grid>
        <Grid item xs={12}></Grid>
        </Grid>
    </React.Fragment >
  )
}