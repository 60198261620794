import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    //maxWidth: 345,
  },
  media: {
    height: 300,
  },
  // ellipsis: {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis'
  // },
  noDecoration: {
    textDecoration: 'none',
    color: 'inherit'
  },
  divider: {
    marginBottom:'10px'
  }
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <a href={props.fileUrl} target="_blank" rel="noreferrer" className={classes.noDecoration}>
      <CardActionArea>
          <CardMedia
            className={classes.media}
            image={props.imageUrl}
            title={props.imageTitle}
          />
        <CardContent>
          <Typography variant="subtitle1" className={classes.ellipsis}>
            {props.cardTitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.cardSubtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
      </a>
      <CardActions>
        <Button size="small" color="primary" target="_blank" href={props.fileUrl}>
          Pobierz
        </Button>
      </CardActions>
    </Card>
  );
}