import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import S4MainPage from '../components/S4MainPage';
import S4SoftwarePage from '../components/S4SoftwarePage';
import S4PricelistPage from '../components/S4PricelistPage';
import S4CatalogsPage from '../components/S4CatalogsPage';
import S4VideoTrainingPage from '../components/S4VideoTrainingPage';
// import S4SivaconPartnerPage from '../components/S4SivaconPartnerPage';
import S8SivaconS8Page from '../components/S8SivaconS8Page';
import S8Sivacon8PTPage from '../components/S8SivaconS8PT.component';
import S8PNEN61439Page from '../components/S8PNEN61439.component';
import S8PricelistPage from '../components/S8PricelistPage.component';
import S8NewslettersPage from '../components/S8NewslettersPage';

import S8MainPage from '../components/S8MainPage';
import S8Video from '../components/S8VideoPage';

export default function Routes() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      {path === '/s4' ?
        <React.Fragment>
          <Route exact path={`${path}/oprogramowanie`} component={S4SoftwarePage} />
          <Route exact path={`${path}/cennik`} component={S4PricelistPage} />
          <Route exact path={`${path}/katalogi`} component={S4CatalogsPage} />
          <Route exact path={`${path}/szkolenia-video`} component={S4VideoTrainingPage} />
          {/* <Route exact path={`${path}/strefa-partnera`} component={S4SivaconPartnerPage} /> */}
          <Route exact path={`${path}`} component={S4MainPage} />
        </React.Fragment>
        :
        <React.Fragment>
          <Route exact path={`${path}`} component={S8MainPage} />
          <Route exact path={`${path}/sivacons8`} component={S8SivaconS8Page} />
          <Route exact path={`${path}/sivacon8pt`} component={S8Sivacon8PTPage} />
          <Route exact path={`${path}/61439`} component={S8PNEN61439Page} />
          <Route exact path={`${path}/cennik`} component={S8PricelistPage} />
          <Route exact path={`${path}/newslettery`} component={S8NewslettersPage} />
          <Route exact path={`${path}/materialy_video`} component={S8Video} />
        </React.Fragment>
      }
    </Switch>
  )
}
