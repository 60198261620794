import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
  player: {
    maxWidth: '1920px',
    width: '100%'
  }
}));

export default function CatalogsPage() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Katalogi, broszury, instrukcje</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Wszystkie podręczniki, broszury, katalogi z zakresu nn</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button
            href="https://publikacje.siemens-info.com/"
            target="_blank"
            variant="outlined"
            color="secondary"
            startIcon={<ChevronRightIcon />}
          >Przejdź do strony
            </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Katalog rozdzielnic SIVACON S4, Alpha DIN, Alpha NF</Typography>
          <Typography variant='body1'>Data: 12.2021</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" 
          target="_blank"
          href="https://publikacje.siemens-info.com/pdf/93/Rozdzielnice%20zasilaj%C4%85ce%20i%20sterownicze%20SIVACON,%20ALPHA%20Katalog.pdf">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
        <Typography variant='subtitle1'>Broszura SIVACON S4</Typography>
          <Typography variant='body1'>Data: 12.2014</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="secondary" 
          target="_blank"
          href="https://publikacje.siemens-info.com/pdf/94/Rozdzielnice%20do%204000A%20SIVACON%20S4%20Broszura.pdf">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Instrukcja montażowa</Typography>
          <Typography variant='body1'>Data: 09.2008</Typography>
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="secondary" 
          target="_blank"
          href="https://pricelist.nazwa.pl/PAKO/cdn/LV_SIVACON_S4_Instrukcja_PL_(2008.09).pdf">
            Pobierz
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}