import React from 'react';
import Drawer from './components/Drawer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from "react-router-dom";
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import FormDialog from './components/FormDialog';
import './App.scss';
import { Switch, Route } from "react-router-dom";
import SelectSwitchboardPage from './components/SelectSwitchboardPage';
import LoginPage from './components/LoginPage';
import PrivateRoute from './routes/PrivateRoute';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink
  },
});

export default function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path='/'>
              <SelectSwitchboardPage />
            </Route>
            <Route exact path='/login'>
              <LoginPage />
            </Route>
            <Route path='/s4'>
              <Drawer />
            </Route>
            <Route path='/3wa'>
              <Drawer />
            </Route>
            <PrivateRoute path='/s8'>
              <Drawer />
            </PrivateRoute>
          </Switch>
          <FormDialog />
        </Router>

      </ThemeProvider>
    </React.Fragment>
  );
}