import { SET_DIALOG_OPEN, SET_DIALOG_USERNAME, SET_DIALOG_PASSWORD, SET_DIALOG_USERNAME_ERROR, SET_DIALOG_PASSWORD_ERROR } from '../actions/setDialogOpen';

const initialState = {
  open: false,
  caller: '',
  username: '',
  usernameError: false,
  password: '',
  passwordError: false
}

export const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG_OPEN:
      return {
        ...state,
        open: action.open,
        caller: action.caller
      }
    case SET_DIALOG_USERNAME:
      return {
        ...state,
        username: action.username
      }
    case SET_DIALOG_PASSWORD:
      return {
        ...state,
        password: action.password
      }
    case SET_DIALOG_USERNAME_ERROR:
      return {
        ...state,
        usernameError: action.error
      }
    case SET_DIALOG_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.error
      }
    default:
      return state
  }
}