import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export default function S8MainPage() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Drodzy partnerzy!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'>
            Na niniejszej stronie zamieszczamy najważniejsze materiały dotyczące systemu rozdzielnic SIVACON S8.
            </Typography>
          <Typography variant='body1' gutterBottom={true}>
            Jeśli potrzebujecie dodatkowej pomocy, prosimy o kontakt:
            </Typography>
          <Typography variant='body1' >
            Michał Twardowski
            </Typography>
          <Typography variant='body1'>
            <Link href='mailto:michal.twardowski@siemens.com'>
              michal.twardowski@siemens.com
              </Link>
          </Typography>
          <Typography variant='body1'>
            tel.: +48 (888) 708-933 
            </Typography>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}