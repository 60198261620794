import React from 'react';
import { Link } from "react-router-dom";

export default function SelectSwitchboardPage() {
  return (
    <React.Fragment>
      <Link to='/s4'>
        <div className="bg s4">
          <div className="caption s4">
            <span className="border">
              Strefa partnera SIVACON S4
          </span>
          </div>
        </div>
      </Link>
      <Link to='/s8'>
      <div className="bg s8">
        <div className="caption s8">
          <span className="border">
          Strefa partnera SIVACON S8
          </span>
        </div>
      </div>
      </Link>
    </React.Fragment >

  
  )
}