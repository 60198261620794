import React, { useEffect } from 'react';
import SelectSwitchBoardPage from './SelectSwitchboardPage';
import { setDialogOpen } from '../actions/setDialogOpen';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

function LoginPage(props) {
  const location = useLocation()
  const { setDialogOpen } = props
  useEffect(() => {
    setDialogOpen(true, location.state.from.pathname || '')
  }, [setDialogOpen])

  return (
    <React.Fragment>
      <SelectSwitchBoardPage />
    </React.Fragment>
  )
}

const mapDispatchToProps = {
  setDialogOpen
}

export default connect(null, mapDispatchToProps)(LoginPage)