import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from './Card.component';

export default function S8NewslettersPage() {
  const folderUrl = 'https://pricelist.nazwa.pl/PAKO/cdn/newsletteryS8';
  const files = [{
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-01.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-01',
    cardTitle: 'SIVACON S8 Product information 2020/01',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-01.pdf`
  },
  {
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-04.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-04',
    cardTitle: 'SIVACON S8 Product information 2020/04',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-04.pdf`
  },
  {
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-04-Flyer-DEHNshort-EN.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-04-Flyer-DEHNshort-EN',
    cardTitle: 'License extension for DEHN short arc fault protection in the SIVACON S8',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-04-Flyer-DEHNshort-EN.pdf`
  },
  {
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-06.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-06',
    cardTitle: 'SIVACON S8 Product information 2020/06',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-06.pdf`
  },
  {
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-07.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-07',
    cardTitle: 'SIVACON S8 Product information 2020/07',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-07.pdf`
  },
  {
    imageUrl: `${folderUrl}/SIVACON-S8-product-information-2020-08-en.jpg`,
    imageTitle: 'SIVACON-S8-product-information-2020-08',
    cardTitle: 'SIVACON S8 Product information 2020/08',
    cardSubtitle: '',
    fileUrl: `${folderUrl}/SIVACON-S8-product-information-2020-08-en.pdf`
  },
  ]

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Newslettery produktowe EN</Typography>
        </Grid>
        {files.map((file, index) => {
          return (<Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card
            imageUrl={file.imageUrl}
            imageTitle={file.imageTitle}
            cardTitle={file.cardTitle}
            cardSubtitle={file.cardSubtitle}
            fileUrl={file.fileUrl} />
          </Grid>)
        })}
      </Grid>
    </React.Fragment>
  );
}