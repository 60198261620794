import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export default function PNEN61439() {
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>PN/EN 61439 Weryfikacja Wyrobu - dokumenty</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Weryfikacja wyrobu - dokumenty</Typography>
          <Typography variant='body1'>Data: 14.04.2015</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_S8_Weryfikacja_wyrobu_dokumenty_PL_(2015_04).zip">
            Pobierz
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}