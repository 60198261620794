import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
  player: {
    maxWidth: '1920px',
    width: '100%'
  }
}));

export default function S8PricelistPage() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Cennik</Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant='subtitle1'>Aktualizacja cennika w wersji 9.0 dla Partnerów S8</Typography>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/Updates/Aktualizuj_Cennik_9.0_DWG_S8.exe">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} /> */}

        <Grid item xs={12}>
          <Typography variant='subtitle1'>Instalacja cennika w wersji 9.1 dla Partnerów S8</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/Install/Instaluj_Cennik_9.1_DWG_S8.exe">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='body1'>Wymagania</Typography>
        </Grid>
        <Grid item xs={12}>
          <ul>
            <li>
              <Link href="https://pricelist.nazwa.pl/Install/dotNetFx20_Full_x86_x64.exe">NET Framework 2.0</Link>
            </li>
            <li>
              <Link href="https://pricelist.nazwa.pl/Install/dotNetFx40_Full_x86_x64.exe">NET Framework 4.0</Link>
            </li>
          </ul>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Cennik - Samouczek z obsługi programu</Typography>
        </Grid>
        <Grid item xs={12}>
          <video className={classes.player} controls>
            <source src="https://pricelist.nazwa.pl/PAKO/cdn/cennik_samouczek.mp4" type="video/mp4" />
              Przeglądarka nie wspiera HTML5 video.
          </video>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}