import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  }
}));

export default function SivaconS8Page() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>SIVACON S8</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Podręcznik techniczny. SIVACON S8. Język angielski.</Typography>
          <Typography variant='body1'>Data: 01.2022</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_S8_Katalog techniczny_EN_(2022.01).pdf">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Simaris Configuration. Wersja instalacyjna. Język angielski / niemiecki.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            href="https://support.industry.siemens.com/cs/document/109740118/simaris-configuration?dti=0&lc=en-DE"
            target="_blank"
            variant="outlined"
            color="secondary"
            startIcon={<ChevronRightIcon />}
          >Przejdź do strony SIOS
            </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Wzory deklaracji zgodności EU. Języki: polski, angielski, niemiecki.</Typography>
          <Typography variant='body1'>Data: 08.08.2017</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_Sivacon_S8_Deklaracja_Wzor_PL_EN_DE_(2017.08).zip">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Deklaracja zgodności SIVACON S8 - Siemens AG. Język angielski i tłumaczenie na język polski.</Typography>
          <Typography variant='body1'>Data: 11.2021</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_Sivacon_S8_Deklaracje_PL_2021.zip">
            Pobierz
          </Button>
        </Grid>

        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Instrukcja obsługi i eksploatacji. SIVACON S8. Język polski.</Typography>
          <Typography variant='body1'>Data: 2021</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_S8_Instrukcja_obslugi_i_eksploatacji_PL_(2021).zip">
            Pobierz
          </Button>
        </Grid>

        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Bloki dynamiczne CAD</Typography>
          <Typography variant='body1'>Data: 13.04.2023</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/EP_S8_Full_2023.04.13.zip">
            Pobierz
          </Button>
        </Grid>

        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Podręcznik projektanta</Typography>
          <Typography variant='body1'>Data: 07.2014</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/LV_S8_Podrecznik_projektanta_PL_(2014.07).pdf">
            Pobierz
          </Button>
        </Grid>

        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Konfiguratory Excel</Typography>
          <Typography variant='body1'>Data: 14.07.2014</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/Konfiguratory_Excel.zip">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Strona dla Partnerów SIVACON S8 – de/en (LMS)</Typography>
          <Typography variant='body1'>Szkolenia, filmy instruktażowe i wiele więcej. Wymagane logowanie.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="secondary"
            target="_blank"
            startIcon={<ChevronRightIcon />}
            href="https://siemens.portal-lms.com/login">
            Przejdź do LMS
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}