import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useWindowSize } from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
  player: {
    maxWidth: '1920px',
    width: '100%'
  }
}));

export default function VideoTrainingPage() {
  const vid = React.useRef(null)
  const classes = useStyles();
  const [width, height] = useWindowSize();

  React.useEffect(() => {

  }, [width, height])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>Szkolenia video</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} direction="row">
        <Grid container item spacing={1} direction="column" xs={12} lg={6}>
          <Grid item>
          <Typography variant='subtitle1'>Bloki dynamiczne CAD - SIVACON S4</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 1 h 58 min</Typography>
            <video className={classes.player} controls>
              <source src="https://pricelist.nazwa.pl/PAKO/cdn/Bloki_S4_CAD_rev.mp4" type="video/mp4" />
              Przeglądarka nie wspiera HTML5 video.
          </video>
          </Grid>
          <Grid item >
            <Typography variant='subtitle1'>Webinar: Pomiary w rozdzielnicach nn zgodnie z PN-EN 61439</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 1 h 55 min</Typography>
            <iframe title="Webinar: Pomiary w rozdzielnicach nn zgodnie z PN-EN 61439" ref={vid} width="100%" height={vid.current !== null ? vid.current.offsetWidth / 1.77 : '100%'} src="https://www.youtube.com/embed/ArPZ4rzS-V8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>
        </Grid>
        <Grid container item spacing={1} direction="column" xs={12} lg={6}>
        <Grid item>
        <Typography variant='subtitle1'>Rozdzielnice SIVACON S4 do 4000 A</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 2 h 02 min</Typography>
            <video className={classes.player} controls>
              <source src="https://pricelist.nazwa.pl/PAKO/cdn/sivacon_s4.mp4" type="video/mp4" />
            Przeglądarka nie wspiera HTML5 video.
          </video>
          </Grid>
          <Grid item >
            <Typography variant='subtitle1'>Webinar: Jak zaprojektować i wyprodukować rozdzielnicę nn?</Typography>
            <Typography variant='body1' paragraph>Czas trwania: 1 h 13 min</Typography>
            <iframe title="Webinar: Jak zaprojektować i wyprodukować rozdzielnicę nn?" ref={vid} width="100%" height={vid.current !== null ? vid.current.offsetWidth / 1.77 : '100%'} src="https://www.youtube.com/embed/ev_fpqGq7iw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
          <Button
            href="https://www.biblioteka.siemens.academy/home"
            target="_blank"
            variant="outlined"
            color="secondary"
            startIcon={<ChevronRightIcon />}
          >Biblioteka szkoleń Siemensa
            </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}