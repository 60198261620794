import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BuildIcon from '@material-ui/icons/Build';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SiemensLogoSVG from '../assets/sie-logo-petrol-rgb.svg';
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import Routes from '../routes/Routes';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MovieIcon from '@material-ui/icons/Movie';
// import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { setDialogOpen } from '../actions/setDialogOpen';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  pageTitle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '120px',
    paddingBottom: '22px',
    paddingTop: '22px'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  selected: {
    backgroundColor: '#000000'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  hidden: {
    display: 'none'
  }
}));

function ResponsiveDrawer(props) {
  const location = useLocation();
  const [pathState, setPathState] = useState("");
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let { path } = useRouteMatch();

  useEffect(() => {
    setPathState(location.pathname);
  }, [location, setPathState]);

  const activeRoute = (route) => {
    return route === pathState;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const hideDrawerOnClickInMobileView = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} onClick={() => hideDrawerOnClickInMobileView()}>
        <Link to="/"><img className={classes.pageTitle} src={SiemensLogoSVG} alt='Logo' /></Link>
      </div>
      <Divider />
      {path==='/s4' ?
        <List onClick={() => hideDrawerOnClickInMobileView()}>
          <ListItem button component={Link} to={`${path}`} selected={activeRoute(path)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Strona główna' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/oprogramowanie`} selected={activeRoute(`${path}/oprogramowanie`)}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary='Oprogramowanie' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/cennik`} selected={activeRoute(`${path}/cennik`)} >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary='Cennik' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/katalogi`} selected={activeRoute(`${path}/katalogi`)}>
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary='Katalogi' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/szkolenia-video`} selected={activeRoute(`${path}/szkolenia-video`)}>
            <ListItemIcon>
              <MovieIcon />
            </ListItemIcon>
            <ListItemText primary='Szkolenia video' />
          </ListItem>
          {/* <Divider />
          <ListItem button component={Link} to={`${path}/strefa-partnera`} selected={activeRoute(`${path}/strefa-partnera`)}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary='Strefa partnera' />
          </ListItem> */}
        </List>
        :
        <List onClick={() => hideDrawerOnClickInMobileView()}>
          <ListItem button component={Link} to={`${path}`} selected={activeRoute(path)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary='Strona główna' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/sivacons8`} selected={activeRoute(`${path}/sivacons8`)}>
            <ListItemIcon>
              <ViewArrayIcon />
            </ListItemIcon>
            <ListItemText primary='SIVACON S8' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/sivacon8pt`} selected={activeRoute(`${path}/sivacon8pt`)}>
            <ListItemIcon>
              <ViewArrayIcon />
            </ListItemIcon>
            <ListItemText primary='SIVACON 8PT' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/61439`} selected={activeRoute(`${path}/61439`)}>
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText primary='PN/EN 61439 Weryfikacja Wyrobu - dokumenty' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/cennik`} selected={activeRoute(`${path}/cennik`)}>
            <ListItemIcon>
            <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary='Cennik' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/newslettery`} selected={activeRoute(`${path}/newslettery`)}>
            <ListItemIcon>
            <MailIcon />
            </ListItemIcon>
            <ListItemText primary='Newslettery produktowe EN' />
          </ListItem>
          <ListItem button component={Link} to={`${path}/materialy_video`} selected={activeRoute(`${path}/materiały_video`)}>
            <ListItemIcon>
            <MovieIcon />
            </ListItemIcon>
            <ListItemText primary='Materiały video' />
          </ListItem>
        </List>
      }
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {`Strona dla partnerów SIVACON ${path==='/s4'?'S4':'S8'}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="page navigation">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  );
}

const mapDispatchToProps = {
  setDialogOpen
}

export default connect(null, mapDispatchToProps)(ResponsiveDrawer)