import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { setDialogOpen, setDialogUsername, setDialogPassword, setDialogPasswordError, setDialogUsernameError } from '../actions/setDialogOpen';
import { setUserData } from '../actions/setUserData';
import AuthService from "../services/auth.service";
import { useHistory } from "react-router-dom";

function FormDialog(props) {
  let history = useHistory();
  const tryLogin = () => {
    AuthService.login(props.username, props.password).then(() => {
      props.setDialogOpen(false)
      props.setDialogPasswordError(false)
      props.setDialogUsernameError(false)
      props.setDialogUsername("")
      props.setDialogPassword("")

      history.push(props.callerPage || '/')      
    },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(resMessage)
        props.setDialogPasswordError(true)
        props.setDialogUsernameError(true)
      }
    );
  }

  const controlFormFields = (field, value) => {
    //reset helpers if user changes text
    props.setDialogUsernameError(false)
    props.setDialogPasswordError(false)
    if (field === 'username') {
      props.setDialogUsername(value)
    }
    else {
      props.setDialogPassword(value)
    }
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={'md'} open={props.open} onClose={() => props.setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Logowanie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zaloguj się, aby uzyskać dostęp do tej zawartości.
          </DialogContentText>
          <form>
            <TextField
              autoComplete='current-user'
              onKeyDown={(e) => e.key === 'Enter' && props.username.length >= 4 && props.password.length >= 4 ? tryLogin() : null}
              onChange={(username) => controlFormFields('username', username.target.value)}
              error={props.usernameError}
              value={props.username}
              autoFocus
              margin="dense"
              id="username"
              label="Nazwa użytkownika"
              type="text"
              fullWidth
              helperText={props.usernameError ? "Nieprawidłowa nazwa użytkownika lub hasło" : "Co najmniej 4 znaki"}
            />
            <TextField
              autoComplete='current-password'
              onKeyDown={(e) => e.key === 'Enter' && props.username.length >= 4 && props.password.length >= 4 ? tryLogin() : null}
              onChange={(pwd) => controlFormFields('password', pwd.target.value)}
              error={props.passwordError}
              value={props.password}
              margin="dense"
              id="password"
              label="Hasło"
              type="password"
              fullWidth
              helperText={props.passwordError ? "Nieprawidłowa nazwa użytkownika lub hasło" : "Co najmniej 4 znaki"}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => props.setDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => tryLogin()} color="primary" disabled={props.username.length < 4 || props.password.length < 4}>
            Zaloguj
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.dialogReducer.open,
    username: state.dialogReducer.username,
    password: state.dialogReducer.password,
    usernameError: state.dialogReducer.usernameError,
    passwordError: state.dialogReducer.passwordError,
    callerPage: state.dialogReducer.caller
  }
}

const mapDispatchToProps = {
  setDialogOpen,
  setDialogUsername,
  setDialogPassword,
  setUserData,
  setDialogPasswordError,
  setDialogUsernameError
}

export default connect(mapStateToProps, mapDispatchToProps)(FormDialog)