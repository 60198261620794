import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  }
}));

export default function SivaconS8Page() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h5' paragraph>SIVACON 8PT</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Aktualizacja Simaris Sivacon 6.3</Typography>
          <Typography variant='body1'>Data: 01.10.2017</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/SIMARIS_Sivacon_6.3_-_Update_102017.7z">
            Pobierz
          </Button>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12}>
          <Typography variant='subtitle1'>Simaris Sivacon 6.3. Wersja instalacyjna i aktualizacje. Wielkość pliku 2,79GB</Typography>
          <Typography variant='body1'>Data: 01.10.2017</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary"
            target="_blank"
            href="https://pricelist.nazwa.pl/PAKO/cdn/Simaris_Sivacon_6.3_Instalka_i_update.zip">
            Pobierz
          </Button>
        </Grid>
      </Grid>
    </React.Fragment >
  )
}